import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal } from '@mui/material';
// Functions : 
import { addAgentToTeam, getFreeAgents } from '../../config/agentsConfig';
// Toast :
import { toast } from 'sonner';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AddAgentModal = ({ updateAgents, onAddAgent }) => {
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState('');
    const [btnClicked, setBtnClicked] = useState(false);

    // ? ============================|| Modal Style ||============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        borderRadius: 3
    };
    // ? =========================================================================

    // ? ============================|| Get All Free Agents ||============================
    useEffect(() => {
        const respoAgents = async () => {
            const result = await getFreeAgents();
            setAgents(result);
        };

        respoAgents();
    }, [btnClicked, updateAgents]);
    // ? =================================================================================

    // ? ===============================|| Toast ||===============================
    const notifySuccess = () => toast.success('L\'agent a été ajouté à l\'équipe avec succès', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (error) => toast.info(`${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? ===============================================================================

    // ? =============================|| Add Agent To Team ||=============================
    const handleAddAgentToTeam = async () => {
        if (selectedAgent === '-1' || selectedAgent === '') {
            // notifyInfo('Vous devez sélectionner un agent')
            toast.info('Vous devez sélectionner un agent');
            setSelectedAgent(''); // Reset select input.
            handleClose(); // Close the modal.
        }
        if (selectedAgent !== '-1' && selectedAgent !== '') {
            await addAgentToTeam(selectedAgent);
            onAddAgent(); // Refresh the table after we add an agent to the team.
            handleClose(); // Close the modal.
            // notifySuccess();
            toast.success('L\'agent a été ajouté à l\'équipe avec succès');
            setSelectedAgent(''); // Reset select input.
            setBtnClicked(!btnClicked); // Refresh the select input after we click on the button 'Ajouter'.
        }
    };
    // ? =================================================================================

    return (
        <div>
            <button
                className='border border-primary py-2 px-6 rounded-md text-primary hover:bg-primary hover:text-white transition-all'
                onClick={handleOpen}
            >
                Ajouter Agent
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Ajouter Agent</p>
                    <div className='mt-10'>

                        <div className='mb-8'>
                            <p className='font-medium mb-2'>Sélectionner un agent</p>
                            <select
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={selectedAgent}
                                onChange={e => setSelectedAgent(e.target.value)}
                            >
                                <option value='-1'>Sélectionner un agent</option>
                                {
                                    agents?.map((agent, i) => (
                                        <option key={i} value={agent?.user_id}>{agent?.fullName}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className='flex justify-end items-center'>
                            <button
                                onClick={handleClose}
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                            >
                                Annuler
                            </button>
                            <button
                                className='bg-primary text-white rounded-md py-2 px-4'
                                onClick={handleAddAgentToTeam}
                            >
                                Ajouter
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
import { addDoc, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { deletedItemsCollection, notificationCollection, pointageCollection } from "./firebase";

export const verifySelected = async (selectedPointage) => {
    selectedPointage.forEach(async (element) => {
        try {
            await verifyPointage(element.key)
        } catch (error) {
        }
    });
};

export const deleteSelectedPointage = async (selectedPointage) => {
    selectedPointage.forEach(async (element) => {
        try {
            await deletePointage(element.key)
        } catch (error) {
        }
    });
};

export const verifyAll = async (pointage) => {
    pointage.forEach(async (element) => {
        try {
            await verifyPointage(element.key)
        } catch (error) {
        }
    });
};

const verifyPointage = async (pointage_id) => {
    try {
        const respo_name = localStorage.getItem("respo_name")

        // Get the pointage doc we want
        const pointageQuery = query(
            pointageCollection,
            where("pointage_id", "==", pointage_id),
        );
        const pointageSnapshot = await getDocs(pointageQuery);
        const pointData = pointageSnapshot.docs.map((doc) => doc.data())[0];

        // Update pointage to set verified: true
        await setDoc(doc(pointageCollection, pointage_id), { verified: true, verifiedBy: respo_name }, { merge: true });

        // Create a summary message
        let data = {
            agent_id: pointData.user_id,
            message: `Votre travail pour cette journé ${formatTimestamp(pointData.date)} a été vérifié avec succès`,
            by: respo_name || '-',
            date: new Date(),
        };

        await addDoc(notificationCollection, data)

    } catch (error) {
        console.error("Error verifying pointage:", error.message);
    }
};

const deletePointage = async (pointage_id) => {
    try {
        const respo_name = localStorage.getItem("respo_name")

        const deletePointage = doc(pointageCollection, pointage_id);

        const deletedPointageSnapshot = await getDoc(deletePointage);
        const deletedPointageData = deletedPointageSnapshot.data();

        // Create a summary message
        let data = {
            date: deletedPointageData.date,
            agent_id: deletedPointageData.user_id,
            message: `Votre travail n'a pas été vérifié pour ce jour ${formatTimestamp(deletedPointageData.date)}`,
            by: respo_name ? respo_name : '-'
        };

        await addDoc(deletedItemsCollection, deletedPointageData);
        await addDoc(notificationCollection, data);
        await deleteDoc(deletePointage);

    } catch (error) {
        console.error("Error verifying pointage:", error.message);
        return false;
    }
};

function formatTimestamp(timestamp) {
    const dateObj = timestamp.toDate();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    return `${day}/${month}/${year}`;
}
import React, { useState, useEffect } from "react";
// Sidebar :
import Sidebar from "../components/Sidebar";
// Firebase :
import { getDocs, orderBy, query, where } from "firebase/firestore";
import { affaireCollection, pointageCollection, userCollection } from "../config/firebase";
// Components :
import { VerifyAllModal } from "../components/verificationComponents/VerifyAllModal";
import { VerifySelectedModal } from "../components/verificationComponents/VerifySelectedModal";
// Ant Design Table :
import { Table } from "antd";
// Toast :
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DeleteVerificationModal } from "../components/verificationComponents/DeleteVerificationModal";
// Icons :
import { IoIosCheckmark, IoIosClose } from "react-icons/io";
import { BsExclamation } from "react-icons/bs";

export const Verification = () => {
    const [pointage, setPointage] = useState([]);
    const [selectedPointage, setSelectedPointage] = useState([]);

    // ? ===========================|| Get All Unverified Pointage ||============================
    const getNotVerifiedPointage = async () => {
        const group_id = localStorage.getItem('group_id');
        const data = [];

        try {
            const agentsQuery = query(
                userCollection,
                where("role", "==", "Agent"),
                where('group_id', '==', group_id)
            );

            const querySnapshot = await getDocs(agentsQuery);
            const agents = querySnapshot.docs.map((doc) => doc.data());

            const affairesSnapshot = await getDocs(affaireCollection);
            const affaires = affairesSnapshot.docs.map((doc) => doc.data());

            const pointageQuery = query(
                pointageCollection,
                where("group_id", "==", group_id),
                where("verified", "==", false),
                orderBy("date", "desc")
            );

            const pointageSnapshot = await getDocs(pointageQuery);
            const pointage = pointageSnapshot.docs.map((doc) => doc.data());

            pointage.forEach((poi) => {
                const projectsNormalWorkName = poi.normalWork.map((work) => {
                    const affaire = affaires.find((aff) => aff.codeAffaire === work.codeAffaire);
                    return {
                        affaireName: affaire ? affaire.codeAffaire + ' - ' + affaire.nameAffaire : "-",
                        hours: work.hours,
                    };
                });
                const projectsExtraWorkName = poi.extraWork.map((work) => {
                    const affaire = affaires.find((aff) => aff.codeAffaire === work.codeAffaire);
                    return {
                        affaireName: affaire ? affaire.codeAffaire + ' - ' + affaire.nameAffaire : "-",
                        hours: work.hours,
                    };
                });

                let agentName = "";
                agents.forEach((element) => {
                    if (poi.user_id === element.user_id) {
                        agentName = element.fullName;
                    }
                });

                let message = '';
                projectsNormalWorkName.forEach((pro) => {
                    message += `${pro.hours}h au ${pro.affaireName}, `;
                });
                projectsExtraWorkName.forEach((pro) => {
                    message += `+${pro.hours}h au ${pro.affaireName}, `;
                });

                // Remove the comma from the last sentence:
                const updatedMessage = message.slice(0, -2);

                let his = {
                    agentName: agentName,
                    totalNormalwork: poi.totalNormalwork,
                    totalExtrawork: poi.totalExtrawork,
                    compDate: poi.date,  // Keep the original timestamp for sorting
                    date: formatTimestamp(poi.date),
                    key: poi.pointage_id,
                    message: updatedMessage,
                    deplacement: poi.deplacement,
                    deplacementPrice: poi.deplacementPrice,
                    transport: poi.transport,
                    transportPrice: poi.transportPrice,
                    holiday: poi.holiday
                };

                data.push(his);
            });

            // const orderedData = data.sort((a, b) => {
            //     // Compare by date in descending order (newest first)
            //     const dateA = new Date(b.compDate);
            //     const dateB = new Date(a.compDate);
            //     return dateA - dateB;
            // });

            setPointage(data);
            console.log('orderedData 2 :', data)
        } catch (error) {
            console.error("Error in timeHandler:", error);
            return false;
        }
    };


    useEffect(() => {
        getNotVerifiedPointage();
    }, []);

    // Time Format Function :
    function formatTimestamp(timestamp) {
        const dateObj = timestamp.toDate();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();

        return `${day}/${month}/${year}`;
    };
    // ? ==========================================================================================


    // ? ==================================|| Table Function ||====================================
    const [selectionType, setSelectionType] = useState("checkbox");
    const columns = [
        {
            title: "Nom d'agent",
            dataIndex: "agentName",
        },
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Message",
            dataIndex: "message",
        },
        {
            title: "Deplacement",
            dataIndex: 'deplacement',
            // render: (deplacement) => deplacement ? <IoIosCheckmark color="green" size={34} /> : <IoIosClose color="red" size={34} />
            render: (deplacement, record) => {
                if (!deplacement) return <IoIosClose color="red" size={34} />

                if (deplacement && record.deplacementPrice !== null && record.deplacementPrice !== "") {
                    return <p>{record.deplacementPrice}DHs</p>;
                } else {
                    return <BsExclamation color="red" size={34} />;
                }
            }
        },
        {
            title: "Transport",
            dataIndex: "transport",
            // render: (transport) => transport ? <IoIosCheckmark color="green" size={34} /> : <IoIosClose color="red" size={34} />
            render: (transport, record) => {
                if (!transport) return <IoIosClose color="red" size={34} />

                if (transport && record.transportPrice !== null && record.transportPrice !== "") {
                    return <p>{record.transportPrice}DHs</p>;
                } else {
                    return <BsExclamation color="red" size={34} />;
                }
            }
        },
        {
            title: "Jour férié",
            dataIndex: "holiday",
            render: (holiday) => holiday ? <IoIosCheckmark color="green" size={34} /> : <IoIosClose color="red" size={34} />
        },
    ];

    const data = pointage;

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedPointage(selectedRows)
        },
    };
    // ? ==========================================================================================

    // ? ==================================|| Refresh table ||=====================================
    const handlOnVerify = () => {
        getNotVerifiedPointage();
    };
    const handlOnDelete = () => {
        getNotVerifiedPointage();
    };
    // ? ==========================================================================================

    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                />

                {/* Title */}
                <p className="text-2xl md:text-3xl lg:text-4xl font-bold">Verification</p>

                <div className="mt-10">
                    {/* Verification Buttons, All & Selected */}
                    <div className="flex flex-wrap justify-end items-center">
                        <div className="flex flex-wrap justify-end items-center">
                            <VerifyAllModal pointage={pointage} onVerify={handlOnVerify} />
                            <VerifySelectedModal selectedPointage={selectedPointage} onVerify={handlOnVerify} />
                            <DeleteVerificationModal selectedPointage={selectedPointage} onDelete={handlOnDelete} />
                        </div>
                    </div>

                    {/* Vefication Table */}
                    <div className="mt-4">
                        <p className="text-gray text-sm mb-2">Ici vous pouvez vérifier, modifier le pointage de vos agents.</p>
                        {/* <p className="text-black text-sm mb-2"><IoIosClose color="red" size={34}/> this means that theres no trasport or deplacement.</p>
                        <p className="text-black text-sm mb-2"><BsExclamation  color="red" size={34} /> this icon means he forget to inser the the price</p> */}
                        <Table
                            rowSelection={{
                                type: selectionType,
                                ...rowSelection,
                            }}
                            columns={columns}
                            dataSource={data}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
};
import React, { useEffect, useState } from "react";
// Material UI :
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
// Packages :
import { Box, IconButton, Modal, Tooltip } from "@mui/material";
// React-select :
import Select from "react-select";
// Icons :
import { AiOutlineEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
// Toast :
import { toast } from "react-toastify";
import { getAllAffaires, getAllAffairesForSelect, getRespoAffaires } from "../../config/affairesConfig";
import { doc, setDoc } from "firebase/firestore";
import { pointageCollection } from "../../config/firebase";

export const EditModal = ({ data, id, onEdit }) => {
    const [affaires, setAffaires] = useState([]);
    const [affaireForSelect, setAffairesForSelect] = useState([]);
    // Tables
    const [normalWorkTable, setNormalWorkTable] = useState([...data?.work?.normalWork]);
    const [extraWorkTable, setExtraWorkTable] = useState([...data?.work?.extraWork,]);
    // Inputs :
    const [selectedAffaire, setSelectedAffaire] = useState("");
    const [selectedHours, setSelectedHours] = useState("");
    const [selectedExtraAffaire, setSelectedExtraAffaire] = useState("");
    const [selectedExtraHours, setSelectedExtraHours] = useState("");
    // Checkboxs :
    const [transportCheck, setTransportCheck] = useState(data?.transport);
    const [deplacementCheck, setDeplacementCheck] = useState(data?.deplacement);
    const [holidayCheck, setHolidayCheck] = useState(data?.holiday);
    const [recuperationsCheck, setRecuperationsCheck] = useState(data?.recuperations);
    const [congeCheck, setCongeCheck] = useState(data?.conge);
    const [maladeCheck, setMaladeCheck] = useState(data?.malade);
    const [absentCheck, setAbsentCheck] = useState(data?.absent);
    const [vacanceCheck, setvacanceCheck] = useState(data?.vacation);
    // Prices :
    const [transportPrice, setTrnaportPrice] = useState(data?.transportPrice);
    const [deplacementPrice, setDeplacementPrice] = useState(data?.deplacementPrice);
    // Message :
    const [message, setMessage] = useState(data?.message);
    // Total Hours :
    const [totalNormalHours, setTotalNormalHours] = useState(data?.totalNormalwork);
    const [totalExtraHours, setTotalExtraHours] = useState(data?.totalExtrawork);
    // Hours :
    const normalHours = [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
    ];
    const extraHours = [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
    ];

    // ? =====================================|| Formate Date ||===================================
    let parts = data?.date.split("/");
    let formattedDate = `${parts[2]}-${parts[1].padStart(
        2,
        "0"
    )}-${parts[0].padStart(2, "0")}`;
    // ? ==========================================================================================

    // ? =====================================|| Checkboxs ||======================================
    const handleCheckboxChange = (n) => {
        if (n === 1) {
            setTransportCheck(!transportCheck);
        } else if (n === 2) {
            setDeplacementCheck(!deplacementCheck);
        } else if (n === 3) {
            setHolidayCheck(!holidayCheck);
        } else if (n === 4) {
            setRecuperationsCheck(!recuperationsCheck);
        } else if (n === 5) {
            setCongeCheck(!congeCheck);
        } else if (n === 6) {
            setMaladeCheck(!maladeCheck);
        } else if (n === 7) {
            setAbsentCheck(!absentCheck);
        } else if (n === 8) {
            setvacanceCheck(!vacanceCheck);
        }
    };
    // ? ==========================================================================================

    // ? ==============================|| Get Responsable Affaires ||==============================
    useEffect(() => {
        const handleGetRespoAffaires = async () => {
            const result2 = await getRespoAffaires();
            setAffaires(result2);
            const result = await getAllAffairesForSelect();
            setAffairesForSelect(result);
        };
        handleGetRespoAffaires();
    }, []);
    // ? ==========================================================================================

    // ? =====================================|| Modal Style ||====================================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState("50%");
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth("90%");
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: width,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: padding,
        borderRadius: 3,
        height: "95vh",
        overflowY: "scroll",
    };
    // ? ==========================================================================================

    // ? ===============================|| Handle Selected Inputs ||===============================
    const handleTransportPrice = (e) => {
        setTrnaportPrice(e.target.value);
    };
    const handleDeplacementPrice = (e) => {
        setDeplacementPrice(e.target.value);
    };
    const handleMessage = (e) => {
        setMessage(e.target.value);
    };
    // ? ==========================================================================================

    // ? =======================================|| Toasts ||=======================================
    const notifySuccess = (message) =>
        toast.success(`${message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const notifyInfo = (message) =>
        toast.info(`${message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const notifyError = (message) =>
        toast.error(`${message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    // ? ===========================================================================================

    // ? ===================================|| Handle Tables ||=====================================
    const handleNormalHoursAdd = () => {
        try {
            if (selectedAffaire !== "" && selectedHours !== "") {
                const newData = { codeAffaire: selectedAffaire, hours: selectedHours };
                setNormalWorkTable([...normalWorkTable, newData]);

                setTotalNormalHours(totalNormalHours + Number(selectedHours));

                // Reset inputs:
                setSelectedAffaire("");
                setSelectedHours("");
            }
            if (selectedAffaire === "" && selectedHours === "") {
                notifyInfo(
                    "Vous devez sélectionner une affaire et les heures de l'affaire"
                );
            }
            if (selectedAffaire === "" && selectedHours !== "") {
                notifyInfo("Vous devriez sélectionner une affaire");
            }
            if (selectedAffaire !== "" && selectedHours === "") {
                notifyInfo("Vous devez sélectionner les heures d'affaire");
            }
        } catch (error) {
            //console.log('Edit modal ', error);
            notifyError("Il y a un problème, veuillez réessayer");
        }
    };
    const handleExtraHoursAdd = () => {
        try {
            if (selectedExtraAffaire !== "" && selectedExtraHours !== "") {
                const newData = {
                    codeAffaire: selectedExtraAffaire,
                    hours: selectedExtraHours,
                };
                setExtraWorkTable([...extraWorkTable, newData]);

                setTotalExtraHours(totalExtraHours + Number(selectedExtraHours));

                // Reset inputs:
                setSelectedExtraAffaire("");
                setSelectedExtraHours("");
            }
            if (selectedExtraAffaire === "" && selectedExtraHours === "") {
                notifyInfo(
                    "Vous devez sélectionner une affaire et les heures de l'affaire"
                );
            }
            if (selectedExtraAffaire === "" && selectedExtraHours !== "") {
                notifyInfo("Vous devriez sélectionner une affaire");
            }
            if (selectedExtraAffaire !== "" && selectedExtraHours === "") {
                notifyInfo("Vous devez sélectionner les heures d'affaire");
            }
        } catch (error) {
            //console.log('handleExtraHoursAdd : ', error)
            notifyError("Il y a un problème, veuillez réessayer");
        }
    };
    // ? ==========================================================================================

    // ? =============================|| Handel Delete From Table ||===============================
    const handleDeleteNormalWork = (index, hours) => {
        const updatedNormalWorkTable = [...normalWorkTable];

        updatedNormalWorkTable.splice(index, 1);

        setNormalWorkTable(updatedNormalWorkTable);

        setTotalNormalHours(totalNormalHours - hours);
    };
    const handleDeleteExtraWork = (index, hours) => {
        const updatedExtraWorkTable = [...extraWorkTable];

        updatedExtraWorkTable.splice(index, 1);

        setExtraWorkTable(updatedExtraWorkTable);

        setTotalExtraHours(totalExtraHours - hours);
    };
    // ? ==========================================================================================

    // ? ==============================|| Handle Update Pointage ||================================
    const handleUpdatePointage = async () => {
        // //console.log(id)
        //console.log(data)
        const group_id = localStorage.getItem("group_id");

        try {
            const newPointageObj = {
                user_id: data?.user_id,
                group_id: group_id,
                verified: true,
                verifiedBy: localStorage.getItem("respo_name"),
                transport: transportCheck,
                transportPrice: transportPrice,
                deplacement: deplacementCheck,
                deplacementPrice: deplacementPrice,
                message: message,
                holiday: holidayCheck,
                vacation: vacanceCheck === undefined ? false : vacanceCheck,
                recuperations: recuperationsCheck === undefined ? false : recuperationsCheck,
                conge: congeCheck === undefined ? false : congeCheck,
                malade: maladeCheck === undefined ? false : maladeCheck,
                absent: absentCheck === undefined ? false : absentCheck,
                normalWork: normalWorkTable,
                extraWork: extraWorkTable,
                totalNormalwork: totalNormalHours,
                totalExtrawork: totalExtraHours,
                totalHours: totalNormalHours + totalExtraHours,
                date: new Date(formattedDate),
            };

            if (normalWorkTable.length > 0 ||
                absentCheck ||
                maladeCheck ||
                congeCheck ||
                vacanceCheck ||
                recuperationsCheck) {

                // const point = await addDoc(pointageCollection, newPointageObj);
                // console.log(newPointageObj)

                await setDoc(doc(pointageCollection, id), newPointageObj, { merge: true });
                onEdit(); // Refresh historique table after editing a pointage
                handleClose();
                notifySuccess("Pointage modifié avec succès");
            } else {
                notifyError('Le pointage n\'est pas valide');
            }
        } catch (error) {
            console.log('handleUpdatePointage :', error);
            notifyError("Il y a un problème, veuillez réessayer");
        }
    };
    // ? ==========================================================================================

    return (
        <div>
            <Tooltip title="Modifier" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineEdit size={20} color="green" />
                </IconButton>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className="text-3xl font-bold">Modifier Pointage</p>
                    <div className="mt-6">
                        <div>
                            {/* Date */}
                            <div>
                                <p className="mb-1 font-medium">Date</p>
                                <input
                                    type="date"
                                    className="w-full border border-gray p-1 rounded-md"
                                    disabled
                                    value={formattedDate}
                                />
                            </div>

                            {/* Normal Work Table */}
                            <div className="mt-4">
                                <p className="mb-1 font-medium">Travail normal</p>
                                <table className="w-full rounded-t-lg">
                                    <thead className="bg-primary text-white py-2">
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Affaire
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Heures d'affaire
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Action
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-light">
                                            <td className="border border-gray py-2 px-3 text-sm lg:text-base">
                                                {/* <select
                                                    className="w-full border border-gray rounded-md outline-none"
                                                    value={selectedAffaire}
                                                    onChange={(e) => setSelectedAffaire(e.target.value)}
                                                >
                                                    <option value="-1">Sélectionnez une affaire</option>
                                                    {affaires.map((aff, index) => (
                                                        <option key={index} value={aff?.codeAffaire}>
                                                            {aff.codeAffaire} - {aff.nameAffaire}
                                                        </option>
                                                    ))}
                                                </select> */}
                                                <Select
                                                    options={affaireForSelect}
                                                    isSearchable={true}
                                                    onChange={(e) => setSelectedAffaire(e.value)}
                                                />
                                            </td>
                                            <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                <select
                                                    className="w-full border border-gray rounded-md"
                                                    value={selectedHours}
                                                    onChange={(e) => setSelectedHours(e.target.value)}
                                                >
                                                    {normalHours.map((hour, index) => (
                                                        <option key={index} value={hour?.value}>
                                                            {hour?.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                <div className="flex justify-center items-center">
                                                    <Tooltip
                                                        title="Ajouter"
                                                        onClick={() => handleNormalHoursAdd()}
                                                        disabled={
                                                            totalNormalHours + Number(selectedHours) > 8
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <IconButton>
                                                            <AiOutlinePlus size={20} color="blue" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                        {normalWorkTable.length > 0
                                            ? normalWorkTable.map((d, index) => (
                                                <tr key={index}>
                                                    <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                        {affaires.map((aff) => {
                                                            if (d.codeAffaire === aff.codeAffaire) {
                                                                return (
                                                                    <p key={aff.codeAffaire}>
                                                                        {aff.codeAffaire} - {aff.nameAffaire}
                                                                    </p>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </td>
                                                    <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                        {d?.hours}
                                                    </td>
                                                    <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                        <div className="flex items-center justify-center cursor-pointer">
                                                            <Tooltip
                                                                title="Supprimer"
                                                                onClick={() =>
                                                                    handleDeleteNormalWork(index, d?.hours)
                                                                }
                                                            >
                                                                <IconButton>
                                                                    <AiOutlineDelete size={20} color="red" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                            : null}
                                    </tbody>
                                </table>
                            </div>

                            {/* Extra Work Table */}
                            <div className="mt-4">
                                <p className="mb-1 font-medium">Heures supplémentaires</p>
                                <table className="w-full rounded-t-lg">
                                    <thead className="bg-primary text-white py-2">
                                        <th className="py-3 px-3 text-sm lg:text-base border border-gray">
                                            Affaire
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-gray">
                                            Heures d'affaire
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-gray">
                                            Action
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-light">
                                            <td className="border border-gray py-2 px-3 text-sm lg:text-base">
                                                {/* <select
                                                    className="w-full border border-gray rounded-md outline-none"
                                                    value={selectedExtraAffaire}
                                                    onChange={(e) =>
                                                        setSelectedExtraAffaire(e.target.value)
                                                    }
                                                >
                                                    <option value="-1">Sélectionnez une affaire</option>
                                                    {affaires.map((aff, index) => (
                                                        <option
                                                            key={index}
                                                            value={aff?.codeAffaire}
                                                            onChange={(e) =>
                                                                setSelectedExtraAffaire(e.target.value)
                                                            }
                                                        >
                                                            {aff.codeAffaire} - {aff.nameAffaire}
                                                        </option>
                                                    ))}
                                                </select> */}
                                                <Select
                                                    options={affaireForSelect}
                                                    isSearchable={true}
                                                    onChange={(e) => setSelectedExtraAffaire(e.value)}
                                                />
                                            </td>
                                            <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                <select
                                                    className="w-full border border-gray rounded-md"
                                                    value={selectedExtraHours}
                                                    onChange={(e) =>
                                                        setSelectedExtraHours(e.target.value)
                                                    }
                                                >
                                                    {extraHours.map((hour, index) => (
                                                        <option key={index} value={hour?.value}>
                                                            {hour?.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                <div className="flex justify-center items-center">
                                                    <Tooltip title="Ajouter">
                                                        <IconButton onClick={() => handleExtraHoursAdd()}>
                                                            <AiOutlinePlus size={20} color="blue" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                        {extraWorkTable.length > 0
                                            ? extraWorkTable.map((d, index) => (
                                                <tr key={index}>
                                                    <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                        {affaires.map((aff) => {
                                                            if (d.codeAffaire === aff.codeAffaire) {
                                                                return (
                                                                    <p key={aff.codeAffaire}>
                                                                        {aff.codeAffaire} - {aff.nameAffaire}
                                                                    </p>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </td>
                                                    <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                        {d?.hours}
                                                    </td>
                                                    <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                                        <div className="flex items-center justify-center cursor-pointer">
                                                            <Tooltip
                                                                title="Supprimer"
                                                                onClick={() =>
                                                                    handleDeleteExtraWork(index, d?.hours)
                                                                }
                                                            >
                                                                <IconButton>
                                                                    <AiOutlineDelete size={20} color="red" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                            : null}
                                    </tbody>
                                </table>
                            </div>

                            {/* Déplacement & Trnasport & Holiday */}
                            <FormGroup className="mt-4">
                                {/* Déplacement */}
                                <div className="flex flex-wrap justify-between items-center mb-2">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={deplacementCheck}
                                                onChange={() => handleCheckboxChange(2)}
                                            />
                                        }
                                        label="Déplacement"
                                        labelPlacement="end"
                                    />
                                    <input
                                        type="number"
                                        value={deplacementPrice}
                                        onChange={handleDeplacementPrice}
                                        disabled={!deplacementCheck}
                                        placeholder="Entrez le prix"
                                        className="w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2 rounded-md border border-gray"
                                    />
                                </div>

                                {/* Trnasport */}
                                <div className="flex flex-wrap justify-between items-center mb-2">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={transportCheck}
                                                onChange={() => handleCheckboxChange(1)}
                                            />
                                        }
                                        label="Transport"
                                        labelPlacement="end"
                                    />
                                    <input
                                        type="number"
                                        value={transportPrice}
                                        onChange={handleTransportPrice}
                                        disabled={!transportCheck}
                                        placeholder="Entrez le prix"
                                        className="w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2 rounded-md border border-gray"
                                    />
                                </div>

                                {/* Holiday */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={holidayCheck}
                                            onChange={() => handleCheckboxChange(3)}
                                        />
                                    }
                                    label="Jour férié"
                                    labelPlacement="end"
                                />

                                {/* Récupérations */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={recuperationsCheck}
                                            onChange={() => handleCheckboxChange(4)}
                                        />
                                    }
                                    label="Récupérations"
                                    labelPlacement="end"
                                />

                                {/* Conge */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={congeCheck}
                                            onChange={() => handleCheckboxChange(5)}
                                        />
                                    }
                                    label="Congé"
                                    labelPlacement="end"
                                />

                                {/* Malade */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={maladeCheck}
                                            onChange={() => handleCheckboxChange(6)}
                                        />
                                    }
                                    label="Malade"
                                    labelPlacement="end"
                                />

                                {/* Absent  */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={absentCheck}
                                            onChange={() => handleCheckboxChange(7)}
                                        />
                                    }
                                    label="Absent(e)"
                                    labelPlacement="end"
                                />

                                {/* Vacation  */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={vacanceCheck}
                                            onChange={() => handleCheckboxChange(8)}
                                        />
                                    }
                                    label="Vacances"
                                    labelPlacement="end"
                                />
                            </FormGroup>

                            {/* Message */}
                            <div className="mt-4">
                                <p className="mb-1 font-medium">Message</p>
                                <input
                                    type="text"
                                    className="w-full border border-gray py-1 px-2 rounded-md outline-none"
                                    value={message}
                                    onChange={handleMessage}
                                />
                            </div>
                        </div>

                        <div className="flex justify-end items-center mt-4">
                            <button
                                onClick={handleClose}
                                className="border border-gray rounded-md py-2 px-4 mr-4"
                            >
                                Annuler
                            </button>

                            <button
                                // onClick={handleDeletePointage}
                                className="bg-primary text-white rounded-md py-2 px-4"
                                onClick={() => handleUpdatePointage()}
                            >
                                Modifier
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

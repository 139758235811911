import React, { useEffect, useState } from "react";
// Components :
import Sidebar from "../components/Sidebar";
import { AddAgentModal } from "../components/agentsComponents/AddAgentModal";
import { DeleteAgentModal } from "../components/agentsComponents/DeleteAgentModal";
import {
    deleteAgentFromTeam,
    getLoanAgents,
    getRespoAgents,
} from "../config/agentsConfig";
import { SendAgentModal } from "../components/agentsComponents/SendAgentModal";
import { GetBackAgent } from "../components/agentsComponents/GetBackAgent";
import { DeleteAgentPermanently } from "../components/agentsComponents/DeleteAgentPermanently";
import Pagination from "../components/pagination/Pagination";
import SearchBar from "../components/searchBar/SearchBar";
// Packages :
import { Skeleton } from "@mui/material";
// Toast :
import { Toaster } from 'sonner';
import { ToastContainer } from "react-toastify";
// Icons :
import { BsDatabaseSlash } from "react-icons/bs";

export const Agents = () => {
    const [agents, setAgents] = useState([]);
    const [loanAgents, setLoanAgents] = useState([]);
    const [addAgentsUpdate, setAddAgentsUpdate] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState(agents);
    const [loading, setLoading] = useState(false);

    const respoAgents = async () => {
        try {
            setLoading(true);
            const result = await getRespoAgents();
            const loanAgents = await getLoanAgents();
            setLoading(false);
            setAgents(result);
            setFilteredData(result);
            setLoanAgents(loanAgents);
        } catch (error) {
            return [];
        }
    };

    useEffect(() => {
        respoAgents();
    }, []);

    const handleDeleteAgent = async (agentId) => {
        try {
            await deleteAgentFromTeam(agentId);
            const updatedAgents = agents.filter((agent) => agent.user_id !== agentId);
            setAgents(updatedAgents);
            setAddAgentsUpdate(!addAgentsUpdate);
            // You can also show a success toast here if needed
        } catch (error) {
            // Handle error and show a toast if necessary
            console.error("Error deleting agent:", error);
        }
    };
    const handleAddAgent = async () => {
        // Fetch the updated agents after adding an agent
        await respoAgents();
    };
    const handleSendAgent = async () => {
        // Fetch the updated agents after sending an agent
        await respoAgents();
    };
    const handleGetBackAgent = async () => {
        await respoAgents();
    };

    // ? ===============================|| Pagination ||===============================
    const itemsPerPage = 10; // Set the number of items per page
    const [currentPageData, setCurrentPageData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Assuming respoAffaires is your data array
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
    // ? ==============================================================================

    // ? =========================|| Handle Search Function ||=========================
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);

        // If the search query is empty, display all agents
        if (searchTerm.trim() === "") {
            setFilteredData(agents);
        } else {
            // Otherwise, filter the data based on the search term
            const filteredResults = agents.filter((agent) => {
                const searchTermLower = searchTerm.toLowerCase();
                const groupID = agent.group_id;

                // Check for an exact match in the group ID
                return (
                    agent.fullName.toLowerCase().includes(searchTermLower) ||
                    groupID === searchTermLower
                );
            });

            setCurrentPage(1);
            setFilteredData(filteredResults);
        }
    };
    // ? ==============================================================================

    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
                {/* <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                /> */}

                <Toaster expand={true} richColors />

                {/* Title & Add Agent Button */}
                <div className="flex justify-between items-center mb-10">
                    <p className="text-2xl md:text-3xl lg:text-4xl font-bold">Équipe</p>
                    <AddAgentModal
                        updateAgents={addAgentsUpdate}
                        onAddAgent={handleAddAgent}
                    />
                </div>

                {/* Search Bar */}
                <SearchBar
                    onSearch={handleSearch}
                    placeholder="Rechercher par nom ou code affaire"
                />

                {/* Tables */}
                <div>
                    <p className="text-gray text-sm mb-2">
                        Ici vous pouvez trouver tous vos agents et vous pouvez ajouter,
                        supprimer un agent
                    </p>

                    {/* Agents Table */}
                    <table className="w-full overflow-hidden rounded-t-lg">
                        <thead className="bg-primary text-white py-2">
                            <tr>
                                <td className="py-3 text-center text-sm lg:text-base border border-white">
                                    Nom et Prénom
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white">
                                    Action
                                </td>
                            </tr>
                        </thead>

                        <tbody className="bg-white">
                            {loading ? (
                                <tr>
                                    <td colSpan={2}>
                                        <Skeleton
                                            animation="wave"
                                            width="98%"
                                            height={50}
                                            className="mx-auto"
                                        />
                                        <Skeleton
                                            animation="wave"
                                            width="98%"
                                            height={50}
                                            className="mx-auto"
                                        />
                                        <Skeleton
                                            animation="wave"
                                            width="98%"
                                            height={50}
                                            className="mx-auto"
                                        />
                                        <Skeleton
                                            animation="wave"
                                            width="98%"
                                            height={50}
                                            className="mx-auto"
                                        />
                                        <Skeleton
                                            animation="wave"
                                            width="98%"
                                            height={50}
                                            className="mx-auto"
                                        />
                                        <Skeleton
                                            animation="wave"
                                            width="98%"
                                            height={50}
                                            className="mx-auto"
                                        />
                                    </td>
                                </tr>
                            ) : currentItems?.length > 0 ? (
                                currentItems?.map((agent, i) => (
                                    <tr key={i}>
                                        <td
                                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                        >
                                            {agent.role === "Respo"
                                                ? `${agent.fullName} (Respo)`
                                                : agent.fullName}
                                        </td>
                                        <td
                                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                        >
                                            {agent.role !== "Respo" && (
                                                <div className="flex items-center justify-center">
                                                    <DeleteAgentModal
                                                        agentName={agent.fullName}
                                                        id={agent.user_id}
                                                        onDelete={() => handleDeleteAgent(agent.user_id)}
                                                    />
                                                    <SendAgentModal
                                                        agentName={agent.fullName}
                                                        id={agent.user_id}
                                                        onSendAgent={() => handleSendAgent()}
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <>
                                    <tr>
                                        <td colSpan="2" className="text-center py-4 text-gray">
                                            <div className="flex flex-col justify-center items-center mt-4">
                                                <BsDatabaseSlash size={30} className="mb-2" />
                                                <p className="text-center text-gray">
                                                    Vous n'avez aucun agent
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        data={filteredData}
                        onPageChange={handlePageChange}
                        currentPage={currentPage}
                    />

                    {/* Agents temporaires */}
                    {loanAgents.length > 0 ? (
                        <>
                            <p className="text-lg md:text-xl lg:text-2xl font-bold mt-6">
                                Agents réaffectés temporairement
                            </p>
                            <table className="w-full overflow-hidden rounded-t-lg mt-2">
                                <thead className="bg-primary text-white py-2">
                                    <tr>
                                        <td className="py-3 text-center text-sm lg:text-base border border-white">
                                            Nom d'Agnet
                                        </td>
                                        <td className="py-3 text-center text-sm lg:text-base border border-white">
                                            Nom du responsable
                                        </td>
                                        <td className="py-3 text-center text-sm lg:text-base border border-white">
                                            Action
                                        </td>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {loanAgents?.map((loanAgents, i) => (
                                        <tr key={i}>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {loanAgents?.fullName}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {loanAgents?.currentRespo}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                <div className="flex items-center justify-center">
                                                    <DeleteAgentPermanently id={loanAgents?.user_id} />
                                                    <GetBackAgent
                                                        id={loanAgents?.user_id}
                                                        onGetBack={() => handleGetBackAgent()}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    ) : null}
                </div>
            </main>
        </div>
    );
};

import React, { useState } from 'react';
// import Pagination from '@mui/material/Pagination';

const Pagination = ({ itemsPerPage, data, onPageChange, currentPage }) => {
    const totalPages = Math.ceil(data.length / itemsPerPage);
    // const [count, setCount] = useState(0);

    const handleClick = (page) => {
        onPageChange(page);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li key={i}>
                    <button onClick={() => handleClick(i)} className={i === currentPage ? 'py-2 px-4 bg-primary text-white m-1 rounded-md' : 'py-2 px-4 bg-light m-1 rounded-md'}>
                        {i}
                    </button>
                </li>
            );
        }

        // setCount(pageNumbers)
        return pageNumbers;
    };

    return (
        <nav aria-label="Pagination">
            <ul className='flex justify-end mt-3'>
                {renderPageNumbers()}
                {/* <Pagination count={count} color="primary" /> */}
            </ul>
        </nav>
    );
};

export default Pagination;

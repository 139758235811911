import React, { useEffect, useState } from "react";
// Material UI :
import { Box, Modal, IconButton, Tooltip } from "@mui/material";
// Toast :
import { toast } from 'sonner';
// import { toast } from "react-toastify"; F
// import "react-toastify/dist/ReactToastify.css";
// Icons :
import { AiOutlineSend } from "react-icons/ai";
// Functions :
import { sendAgentToRespo } from "../../config/agentsConfig";
import { getAllGroups } from "../../config/groups";

export const SendAgentModal = ({ id, onSendAgent, agentName }) => {
    const [selectedGroup, setselectedGroup] = useState("");
    const [groups, setGroups] = useState([]);

    // ? ============================|| Modal Style ||================================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState("50%");
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth("90%");
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: width,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: padding,
        borderRadius: 3,
    };
    // ? ==============================================================================

    // ? =================================|| Toast ||==================================
    const notifySuccess = () =>
        toast.success("L'agent a envoyé avec succès à l'autre responsable", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const notifyInfo = (error) =>
        toast.info(`${error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    // ? ==============================================================================

    // ? ============================|| Get All Other Responsables ||==================
    useEffect(() => {
        const handleGetGroups = async () => {
            const result = await getAllGroups();
            setGroups(result);
        };

        handleGetGroups();
    }, []);
    // ? ==============================================================================

    // ? ===================|| Send Agent To Another Responsable  ||===================
    const handleSendAgentToGroup = async () => {
        if (selectedGroup === "-1" || selectedGroup === "") {
            handleClose();
            // notifyInfo("Vous devez sélectionner un agent !");
            toast.info('Vous devez sélectionner un agent !');
            setselectedGroup("");
        }
        if (selectedGroup !== "-1" && selectedGroup !== "") {
            try {
                await sendAgentToRespo(selectedGroup, id);
                onSendAgent(); // Refresh the table after we send and agent.
                handleClose(); // Close the modal.
                // notifySuccess();
                toast.success('L\'agent a envoyé avec succès à l\'autre responsable');
                setselectedGroup(""); // Reset selecte input.
            } catch (error) {
                handleClose(); // Close the modal.
                // notifyInfo("Il y a un problème, veuillez réessayer");
                toast.info('Il y a un problème, veuillez réessayer !');
                setselectedGroup(""); // Reset selecte input.
            }
        }
    };
    // ? ==============================================================================

    return (
        <div>
            <Tooltip title="Envoyer" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineSend size={20} color="blue" />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className="text-3xl font-bold">Envoyer Agent</p>
                    <div className="mt-10">
                        <div className="mb-6">
                            <p className="mb-4">
                                À quel responsable souhaitez-vous envoyer cet agent{" "}
                                <b>{agentName}</b> ?
                            </p>
                            <p className="mb-2">Sélectionner un responsable</p>
                            <select
                                className="border border-gray w-full rounded-md py-2 px-3"
                                value={selectedGroup}
                                onChange={(e) => setselectedGroup(e.target.value)}
                            >
                                <option value="-1">Sélectionner un responsable</option>
                                {groups?.map((gr, i) => (
                                    <option key={i} value={gr?.group_id}>
                                        {gr?.group_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="flex justify-end items-center">
                            <button
                                onClick={handleClose}
                                className="border border-gray rounded-md py-2 px-4 mr-4"
                            >
                                Annuler
                            </button>
                            <button
                                className="bg-primary text-white rounded-md py-2 px-4"
                                onClick={handleSendAgentToGroup}
                            >
                                Envoyer
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

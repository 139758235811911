import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal, IconButton, Tooltip } from '@mui/material';
// Toast :
import { toast } from 'sonner';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// Icons : 
import { AiOutlineDelete } from 'react-icons/ai';
// Functions :
import { deleteAgentFromTeam } from '../../config/agentsConfig';
// Assets : 
import Loading from '../../assets/img/white-loading.gif';

export const DeleteAgentModal = ({ id, onDelete, agentName }) => {
    const [isLoading, setIsLoading] = useState(false);

    // ? ============================|| Modal Style ||============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        borderRadius: 3
    };
    // ? =========================================================================

    // ? ===============================|| Toast ||===============================
    const notifySuccess = () => toast.success('L\'agent a été supprimé de l\'équipe avec succès.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (error) => toast.info(`${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? =========================================================================

    // ? ============================|| Delete Agent From Team ||============================
    const handleDeleteAgentFromTeam = async () => {
        setIsLoading(true);
        try {
            await deleteAgentFromTeam(id);
            onDelete(); // Call the callback to update the agents state in the parent component (Agents.jsx)
            handleClose(); // Close the modal.
            // notifySuccess();
            toast.success('L\'agent a été supprimé de l\'équipe avec succès');
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            // notifyInfo('Il y a un problème, veuillez réessayer');
            toast.info('Il y a un problème, veuillez réessayer !');
            handleClose(); // Close the modal.
        }
    }
    // ? ====================================================================================

    return (
        <div>
            <Tooltip title="Supprimer" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineDelete size={20} color='red' />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Supprimer Agent</p>
                    <div className='mt-6'>
                        <p className='text-lg mb-6'>Êtes-vous sûr de vouloir supprimer définitivement cet agent de votre équipe <b>{agentName}</b> ?</p>
                        <div className='flex justify-end items-center'>
                            <button onClick={handleClose} className='border border-gray rounded-md py-2 px-4 mr-4'>Annuler</button>
                            <button onClick={handleDeleteAgentFromTeam} className='bg-primary text-white rounded-md py-2 px-4'>
                                {
                                    isLoading ? <img src={Loading} alt='' className='w-6' /> : <p>Oui, je suis sûr</p>
                                }
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

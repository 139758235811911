// Routes :
import { Routes, Route } from "react-router-dom";
// Pages :
import { Agents } from "./pages/Agents";
import { Affaires } from "./pages/Affaires";
import { AgentsHistorique } from "./pages/AgentsHistorique";
import { AgentsPointage } from "./pages/AgentsPointage";
import { Profile } from "./pages/Profile";
import { Verification } from "./pages/Verification";
import { Login } from "./pages/Login";
import { ProtectedRoutes } from "./components/ProtectedRoutes";
import { Dashboard } from "./pages/Dashboard";
import { AffaireHistorique } from "./pages/AffaireHistorique";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />

            <Route path='/apercu' element={
                <ProtectedRoutes>
                    <Dashboard />
                </ProtectedRoutes>
            } />

            <Route path='/affaires' element={
                <ProtectedRoutes>
                    <Affaires />
                </ProtectedRoutes>
            } />

            <Route path='/verification' element={
                <ProtectedRoutes>
                    <Verification />
                </ProtectedRoutes>
            } />

            <Route path='/equipe' element={
                <ProtectedRoutes>
                    <Agents />
                </ProtectedRoutes>
            } />

            <Route path='/pointage' element={
                <ProtectedRoutes>
                    <AgentsPointage />
                </ProtectedRoutes>
            } />

            <Route path='/equipe-historique' element={
                <ProtectedRoutes>
                    <AgentsHistorique />
                </ProtectedRoutes>
            } />

            <Route path='/affaire-historique' element={
                <ProtectedRoutes>
                    <AffaireHistorique />
                </ProtectedRoutes>
            } />

            <Route path='/profile' element={
                <ProtectedRoutes>
                    <Profile />
                </ProtectedRoutes>
            } />
        </Routes>
    );
}

export default App;
import React, { useEffect, useState } from "react";
// Material UI :
import { Box, Modal } from "@mui/material";
// Toast :
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Functions :
// Assets :
import Loading from "../../assets/img/white-loading.gif";
import { MdGroupAdd } from "react-icons/md";
import { Select } from "antd";
import {
    getRespoAgents,
    getRespoAgentsNamdAndId,
} from "../../config/agentsConfig";
import { addDoc, doc, setDoc } from "firebase/firestore";
import { pointageCollection } from "../../config/firebase";

export const PointageCongeMalade = () => {
    const [isLoading, setIsLoading] = useState(false);

    // Inputs :
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // ? ===============================|| Modal Style ||===============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState("50%");
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth("90%");
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: width,
        maxHeight: "95vh",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: padding,
        borderRadius: 3,
        overflowY: 'scroll'
    };
    // ? ===============================================================================

    // ? ===================|| Get Respo Agents Full name and ID ||=====================
    useEffect(() => {
        const handleGetAgents = async () => {
            const result = await getRespoAgents();
            setAgents(result);
        };

        handleGetAgents();
    }, []);
    // ? ===============================================================================

    // ? ==================================|| Toast ||==================================
    const notifySuccess = (message) =>
        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const notifyInfo = (message) =>
        toast.info(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const notifyError = (message) =>
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    // ? ===============================================================================

    // ? ================================|| Pointage Agent ||===========================
    function getDatesInRange(startDate, endDate) {
        const dateArray = [];
        let currentDate = new Date(startDate);
        const end = new Date(endDate);

        while (currentDate <= end) {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    }

    const pointagePeriod = async () => {
        try {
            setIsLoading(true);

            const group_id = localStorage.getItem("group_id");
            const respo_name = localStorage.getItem("respo_name");

            if (selectedAgent === "" || startDate === "" || endDate === "") {
                notifyInfo("Veuillez d'abord sélectionner l'agent et les dates");
                setIsLoading(false);
            }

            if (startDate >= endDate) {
                notifyInfo("La période sélectionnée est incorrecte");
                setIsLoading(false);
            } else {
                const datesInRange = getDatesInRange(startDate, endDate);

                datesInRange.map(async (d) => {
                    const pointageObj = {
                        user_id: selectedAgent,
                        group_id: group_id,
                        verified: true,
                        verifiedBy: respo_name,
                        transport: false,
                        transportPrice: 0,
                        deplacement: false,
                        deplacementPrice: 0,
                        message: "",
                        holiday: false,
                        recuperations: false,
                        conge: true,
                        malade: false,
                        absent: false,
                        normalWork: [],
                        extraWork: [],
                        totalNormalwork: 0,
                        totalExtrawork: 0,
                        totalHours: 0,
                        date: new Date(d),
                    };

                    const point = await addDoc(pointageCollection, pointageObj);
                    await setDoc(
                        doc(pointageCollection, point.id),
                        { pointage_id: point.id },
                        { merge: true }
                    );
                });

                notifySuccess("Congé enregistré avec succès");
                handleClose();
                setSelectedAgent("");
                setStartDate("");
                setEndDate("");
            }
        } catch (error) {
            notifyError("Veuillez réessayer !");
            handleClose();
            setIsLoading(false);
        }
    };

    const handleAddAgent = async () => {
        try {
            setIsLoading(true);
            if (true) {
                const result = await pointagePeriod();
                if (result === "Created") {
                    notifySuccess("Agent ajouté avec succès");
                    handleClose();

                    // // reset inputs :
                    // setAgentName('');
                    // setAgentCin('');
                    // setAgentCnss('');
                    // setAgentEmail('');
                    // setAgentPhone('');
                    // setAgentPricePerDay('');
                    // setAgentGroupId('');

                    setIsLoading(false);
                } else if (result === "Exist") {
                    notifyInfo("l'email existe déjà, veuillez réessayer");
                    handleClose();
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                // handleClose();
                notifyInfo(
                    "Veuillez remplir le formulaire, le nom et l'adresse e-mail de l'agent sont importants"
                );
            }
        } catch (error) {
            notifyError("Veuillez réessayer !");
            handleClose();
            setIsLoading(false);
        }
    };
    // ? ===============================================================================

    // ? ============================|| Handle Enter Click ||===========================
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleAddAgent();
        }
    };
    // ? ===============================================================================

    // ? ===============================|| Handle Selected Inputs ||===============================
    const handleSelectedAgent = (e) => {
        setSelectedAgent(e);
    };
    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    };
    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    };
    // ? ==========================================================================================

    return (
        <div>
            <div className="">
                <button
                    className="border border-primary py-2 px-6 rounded-md text-primary hover:bg-primary hover:text-white transition-all"
                    onClick={handleOpen}
                >
                    Pointage Congé
                </button>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className="text-3xl font-bold">Pointage Congé</p>
                    <div className="mt-5">
                        {/* Agent */}
                        <div className="w-full mb-2">
                            <p className="font-medium mb-1">Agent</p>
                            <select
                                className="w-full border border-gray rounded-md outline-none py-2"
                                value={selectedAgent}
                                onChange={(e) => handleSelectedAgent(e.target.value)}
                            >
                                <option value="-1">Select Agent</option>
                                {agents.map((ag, index) => (
                                    <option key={index} value={ag?.user_id}>
                                        {ag.fullName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Date */}
                        <div className="flex flex-wrap justify-between items-center">
                            {/* Start Date */}
                            <div className="w-full lg:w-1/2 pr-0 lg:pr-2">
                                <p className="font-medium">Date de début</p>
                                <input
                                    type="date"
                                    className="w-full border border-gray rounded-md py-2 px-4"
                                    value={startDate}
                                    onChange={handleStartDate}
                                />
                            </div>

                            {/* End Date */}
                            <div className="w-full lg:w-1/2 mt-2 md:mt-0">
                                <p className="font-medium">Date de fin</p>
                                <input
                                    type="date"
                                    className="w-full border border-gray rounded-md py-2 px-4"
                                    value={endDate}
                                    onChange={handleEndDate}
                                />
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="flex justify-end items-center mt-10">
                            <button
                                onClick={handleClose}
                                className="border border-gray rounded-md py-2 px-4 mr-4"
                            >
                                Annuler
                            </button>

                            <button
                                onClick={() => pointagePeriod()}
                                className="bg-primary text-white rounded-md py-2 px-4"
                            >
                                {isLoading ? (
                                    <img src={Loading} alt="" className="w-6" />
                                ) : (
                                    <p>Valider</p>
                                )}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

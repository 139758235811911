import React, { useEffect, useState } from "react";
// Components :
import Sidebar from "../components/Sidebar";
// Packages :
import { Skeleton } from "@mui/material";
import Switch from "@mui/material/Switch";
// Toast :
import { ToastContainer } from "react-toastify";
// Icons :
import { BsDatabaseSlash } from "react-icons/bs";
import { getdata } from "../config/DashboardConfig";

export const Dashboard = () => {
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState([]);
  const [details, setDetails] = useState(false);

  // ? ===============================|| data functions ||===========================

  const getDays = (date) => {
    // Create a Date object for the first day of the specified month and year
    const firstDayOfMonth = new Date(date);

    // Get the index of the first day of the month in the dayNames array
    const firstDayIndex = firstDayOfMonth.getDay();

    // Define the array of day names
    const dayNames = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];

    // Create an array to store the 16 day names
    const result = [];

    // Loop to populate the result array with the next 16 days
    for (let i = 0; i < 7; i++) {
      // Calculate the index of the current day name using modulo operation
      const index = (firstDayIndex + i - 1) % 7;
      // Push the corresponding day name to the result array
      result.push(dayNames[index]);
    }
    result.unshift("Nom d'employé");
    setDays(result);
    // return result;
  };

  const colorFunc = (num) => {
    try {
      switch (num) {
        case 1:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-green";

        case 2:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-yellow";

        case 3:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-gray";

        case 4:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-lightBlue";

        case 5:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-purple";

        case 6:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-softRed";

        case 7:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-blue";

        case 8:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-[red]";

        case 9:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-white";

        case 10:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-pink";

        case 11:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-orange";

        default:
          return "text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 bg-white invisible";
      }
    } catch (error) {
      console.error("Error in colorFunc:", error);
      return "defaultClass";
    }
  };

  // ? ==============================================================================
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const currentDate = new Date();
      const thisMonth = currentDate.toISOString().slice(0, 7);

      setSelectedMonth(thisMonth);

      const data = await getdata(thisMonth);
      setData(data);

      getDays(thisMonth);

      setLoading(false);
    };

    fetchData();
  }, []);

  const handleMonthChange = async (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);
    const data = await getdata(selectedMonth);
    setData(data);

    getDays(selectedMonth)
  };
  
  // ? ==============================================================================

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const changeDetails = () => {
    setDetails(!details);
  };
  // ? ==============================================================================

  return (
    <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
      <Sidebar />
      <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="colored"
        />

        {/* Title */}
        <div className="flex justify-between items-center">
          <p className="text-2xl md:text-3xl lg:text-4xl font-bold">Aperçu</p>
        </div>

        <div className="">
          {/* picker and details */}
          <div className="flex flex-wrap justify-between items-end">
            <div className="w-full lg:w-2/3 mt-10 lg:flex justify-between items-center">
              {/* Select Date */}
              <div className="w-full lg:w-1/3 mr-2">
                <p className="font-medium">Select a Month</p>
                <input
                  type="month"
                  className="w-full border border-gray rounded-md py-2 px-4"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                />
              </div>
              <div className=" flex items-center w-full lg:w-1/3 mr-20 mt-5">
                <p className="font-medium m-2 p-2">Détails</p>
                <Switch {...label} onClick={changeDetails} />
              </div>
            </div>
          </div>

          {/* colors */}
          <div className="mt-2 flex flex-col flex-wrap lg:flex-row lg:justify-between lg:items-center mb-2">
            <div className="mb-2 lg:mb-0 mr-8 flex text-center items-center mt-2">
              <div className="w-6 h-6 bg-green rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">
                Pointé et vérifié
              </span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-yellow rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">
                Pointé et non vérifié
              </span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 rounded-full border border-black bg-[red]"></div>
              <span className="text-sm text-black ml-2 pt-1">Non Pointé</span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-purple rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">Congé</span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-blue rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">Malade</span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-softRed rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">Absent(e)</span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-pink rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">fête aïd</span>
            </div>
            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-orange rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">Jour férié</span>
            </div>
            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-gray rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">
                Il n'a pas travaillé dimanche
              </span>
            </div>
            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-lightBlue rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">
                Il n'a pas travaillé dimanche (Avec deplacement)
              </span>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="table-wrp block max-h-100">
          {details ? (
            <table className="w-full mt-0 table-fixed">
              <thead className="bg-primary text-white py-2 border-b sticky top-0">
                <tr>
                  {/* <th className="py-3 text-center text-sm lg:text-base border border-white">
                    Nom d'employé
                  </th> */}
                  {days.map((d, i) => (
                    <th
                      className="py-3 text-center text-sm lg:text-base border border-white"
                      key={i}
                      colSpan={2}
                    >
                      {d}
                    </th>
                  ))}
                </tr>
              </thead>

              {loading ? (
                <tbody className="bg-white">
                  <tr>
                    <td colSpan={14}>
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                    </td>
                  </tr>
                </tbody>
              ) : data?.length > 0 ? (
                data?.map((poi, i) => (
                  <tbody key={i} className=" h-96 overflow-y-auto">
                    <tr className="mt-2">
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2`}
                        rowSpan={10}
                        colSpan={2}
                      >
                        {poi.name}
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[0]?.["1"]
                        )}`}
                      >
                        1
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[0]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[1]?.["2"]
                        )}`}
                      >
                        2
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[1]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[2]?.["3"]
                        )}`}
                      >
                        3
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[2]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[3]?.["4"]
                        )}`}
                      >
                        4
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[3]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[4]?.["5"]
                        )}`}
                      >
                        5
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[4]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[5]?.["6"]
                        )}`}
                      >
                        6
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[5]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[6]?.["7"]
                        )}`}
                      >
                        7
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[6]?.totalHours} h
                      </td>
                    </tr>
                    <tr className="mt-2">
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[0]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[1]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[2]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[3]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[4]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[5]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[6]?.codeAff}
                      </td>
                    </tr>

                    <tr className="mt-2">
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[7]?.["8"]
                        )}`}
                      >
                        8
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[7]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[8]?.["9"]
                        )}`}
                      >
                        9
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[8]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[9]?.["10"]
                        )}`}
                      >
                        10
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[9]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[10]?.["11"]
                        )}`}
                      >
                        11
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[10]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[11]?.["12"]
                        )}`}
                      >
                        12
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[11]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[12]?.["13"]
                        )}`}
                      >
                        13
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[12]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[13]?.["14"]
                        )}`}
                      >
                        14
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[13]?.totalHours} h
                      </td>
                    </tr>
                    <tr className="mt-2">
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[7]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[8]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[9]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[10]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[11]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[12]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[13]?.codeAff}
                      </td>
                    </tr>

                    <tr className="mt-2">
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[14]?.["15"]
                        )}`}
                      >
                        15
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[14]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[15]?.["16"]
                        )}`}
                      >
                        16
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[15]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[16]?.["17"]
                        )}`}
                      >
                        17
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[16]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[17]?.["18"]
                        )}`}
                      >
                        18
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[17]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[18]?.["19"]
                        )}`}
                      >
                        19
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[18]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[19]?.["20"]
                        )}`}
                      >
                        20
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[19]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[20]?.["21"]
                        )}`}
                      >
                        21
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[20]?.totalHours} h
                      </td>
                    </tr>
                    <tr className="mt-2">
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[14]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[15]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[16]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[17]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[18]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[19]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[20]?.codeAff}
                      </td>
                    </tr>

                    <tr className="mt-2">
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[21]?.["22"]
                        )}`}
                      >
                        22
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[21]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[22]?.["23"]
                        )}`}
                      >
                        23
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[22]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[23]?.["24"]
                        )}`}
                      >
                        24
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[23]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[24]?.["25"]
                        )}`}
                      >
                        25
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[24]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[25]?.["26"]
                        )}`}
                      >
                        26
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[25]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[26]?.["27"]
                        )}`}
                      >
                        27
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[26]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[27]?.["28"]
                        )}`}
                      >
                        28
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[27]?.totalHours} h
                      </td>
                    </tr>
                    <tr className="mt-2">
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[21]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[22]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[23]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[24]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[25]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[26]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[27]?.codeAff}
                      </td>
                    </tr>

                    <tr className="mt-2">
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[28]?.["29"]
                        )}`}
                      >
                        29
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[28]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[29]?.["30"]
                        )}`}
                      >
                        30
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                      >
                        {poi.days[29]?.totalHours} h
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[30]?.["31"]
                        )}`}
                      >
                        31
                      </td>
                      <td
                        className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold ${colorFunc(
                          poi.days[30]?.["31"]
                        )}`}
                      >
                        {poi.days[30]?.totalHours} h
                      </td>
                    </tr>
                    <tr className="mt-2">
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[28]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                      >
                        {poi.days[29]?.codeAff}
                      </td>
                      <td
                        colSpan={2}
                        className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white ${colorFunc(
                          poi.days[30]?.["31"]
                        )}`}
                      >
                        {poi.days[30]?.codeAff}
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tbody className="bg-white">
                  <tr>
                    <td colSpan="15" className="text-center py-4 text-gray">
                      <div className="flex flex-col justify-center items-center mt-4">
                        <BsDatabaseSlash size={30} className="mb-2" />
                        <p>Pas de données disponibles.</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          ) : (
            <table className="w-full mt-0 table-auto">
              <thead className="bg-primary text-white py-2 border-b sticky top-0">
                <tr>
                  {/* <th className="py-3 text-center text-sm lg:text-base border border-white ">
                    Nom d'employé
                  </th> */}
                  {days.map((d, i) => (
                    <th
                      className="py-3 text-center text-sm lg:text-base border border-white "
                      key={i}
                    >
                      {d}
                    </th>
                  ))}
                </tr>
              </thead>

              {loading ? (
                <tbody className="bg-white">
                  <tr>
                    <td colSpan={8}>
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                      <Skeleton
                        animation="wave"
                        width="98%"
                        height={50}
                        className="mx-auto"
                      />
                    </td>
                  </tr>
                </tbody>
              ) : data?.length > 0 ? (
                data?.map((poi, i) => (
                  <tbody key={i} className=" h-90 overflow-y-auto">
                    <tr className="mt-2">
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2`}
                        rowSpan={5}
                      >
                        {poi.name}
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[0]?.["1"]
                        )}`}
                      >
                        1
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 ${colorFunc(
                          poi.days[1]?.["2"]
                        )}`}
                      >
                        2
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[2]?.["3"]
                        )}`}
                      >
                        3
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[3]?.["4"]
                        )}`}
                      >
                        4
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[4]?.["5"]
                        )}`}
                      >
                        5
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[5]?.["6"]
                        )}`}
                      >
                        6
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[6]?.["7"]
                        )}`}
                      >
                        7
                      </td>
                    </tr>
                    <tr>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[7]?.["8"]
                        )}`}
                      >
                        8
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[8]?.["9"]
                        )}`}
                      >
                        9
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[9]?.["10"]
                        )}`}
                      >
                        10
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[10]?.["11"]
                        )}`}
                      >
                        11
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[11]?.["12"]
                        )}`}
                      >
                        12
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[12]?.["13"]
                        )}`}
                      >
                        13
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[13]?.["14"]
                        )}`}
                      >
                        14
                      </td>
                    </tr>
                    <tr>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[14]?.["15"]
                        )}`}
                      >
                        15
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[15]?.["16"]
                        )}`}
                      >
                        16
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[16]?.["17"]
                        )}`}
                      >
                        17
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[17]?.["18"]
                        )}`}
                      >
                        18
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[18]?.["19"]
                        )}`}
                      >
                        19
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[19]?.["20"]
                        )}`}
                      >
                        20
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[20]?.["21"]
                        )}`}
                      >
                        21
                      </td>
                    </tr>
                    <tr>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[21]?.["22"]
                        )}`}
                      >
                        22
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[22]?.["23"]
                        )}`}
                      >
                        23
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[23]?.["24"]
                        )}`}
                      >
                        24
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[24]?.["25"]
                        )}`}
                      >
                        25
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[25]?.["26"]
                        )}`}
                      >
                        26
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[26]?.["27"]
                        )}`}
                      >
                        27
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[27]?.["28"]
                        )}`}
                      >
                        28
                      </td>
                    </tr>
                    <tr>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[28]?.["29"]
                        )}`}
                      >
                        29
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[29]?.["30"]
                        )}`}
                      >
                        30
                      </td>
                      <td
                        className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                          poi.days[30]?.["31"]
                        )}`}
                      >
                        31
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tbody className="bg-white">
                  <tr>
                    <td colSpan="15" className="text-center py-4 text-gray">
                      <div className="flex flex-col justify-center items-center mt-4">
                        <BsDatabaseSlash size={30} className="mb-2" />
                        <p>Pas de données disponibles.</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          )}
        </div>
      </main>
    </div>
  );
};

import { getDocs } from "firebase/firestore";
import { groupsCollection } from "./firebase";

export const getAllGroups = async () => {
    try {

        const querySnapshot = await getDocs(groupsCollection);
        const groups = querySnapshot.docs.map((doc) => doc.data());


        groups.sort((a, b) => {
            const groupIdA = parseInt(a.group_id);
            const groupIdB = parseInt(b.group_id);
            return groupIdA - groupIdB;
        });

        return groups;
    } catch (error) {
        return [];
    }
};

export const getGroups = async () => {
    try {

        const querySnapshot = await getDocs(groupsCollection);
        const groups = querySnapshot.docs.map((doc) => {
            const { group_name, group_id, shortName } = doc.data();
            return { label: group_name, value: group_id, shortName: shortName };
        });


        return groups;
    } catch (error) {
        return [];
    }
};

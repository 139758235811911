import { doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { userCollection } from "./firebase";
import { getAllGroups } from "./groups";

export const getRespoAgents = async () => {
  const group_id = localStorage.getItem("group_id");

  try {
    const agentsQuery = query(
      userCollection,
      where("group_id", "==", group_id)
      // where('role', '==', "Agent")
    );

    const querySnapshot = await getDocs(agentsQuery);
    let agents = querySnapshot.docs.map((doc) => doc.data());

    // Reorder the agents so that those with the role "Respo" appear at the top
    agents.sort((a, b) => {
      if (a.role === "Respo" && b.role !== "Respo") return -1;
      if (a.role !== "Respo" && b.role === "Respo") return 1;
      return 0;
    });

    return agents;
  } catch (error) {
    console.error("Error fetching agents:", error);
    throw error; // Rethrow the error for handling in the calling code
  }
};

export const getFreeAgents = async () => {
  try {
    const agentsQuery = query(
      userCollection,
      where("group_id", "in", ["", null]),
      where("role", "==", "Agent")
    );

    const querySnapshot = await getDocs(agentsQuery);
    const agents = querySnapshot.docs.map((doc) => doc.data());

    return agents;
  } catch (error) {}
};

export const sendAgentToRespo = async (selectedGroup, idAgent) => {
  try {
    await setDoc(
      doc(userCollection, idAgent),
      { group_id: selectedGroup },
      { merge: true }
    );
    return true;
  } catch (e) {
    return false;
  }
};

export const addAgentToTeam = async (idAgent) => {
  try {
    const group_id = localStorage.getItem("group_id");

    await setDoc(
      doc(userCollection, idAgent),
      { group_id: group_id },
      { merge: true }
    );
    return true;
  } catch (e) {
    return false;
  }
};

export const deleteAgentFromTeam = async (idAgent) => {
  try {
    await setDoc(
      doc(userCollection, idAgent),
      { group_id: "" },
      { merge: true }
    );
    return true;
  } catch (e) {
    return false;
  }
};

export const getRespos = async () => {
  try {
    const group_id = localStorage.getItem("group_id");

    const resposQuery = query(
      userCollection,
      where("role", "==", "Respo"),
      where("group_id", "!=", group_id)
    );

    const querySnapshot = await getDocs(resposQuery);
    const respos = querySnapshot.docs.map((doc) => doc.data());

    return respos;
  } catch (error) {}
};

export const getRespoAgentsNamdAndId = async () => {
  const group_id = localStorage.getItem("group_id");

  try {
    const agentsQuery = query(
      userCollection,
      where("group_id", "==", group_id),
      where("role", "==", "Agent")
    );

    const querySnapshot = await getDocs(agentsQuery);
    const agents = querySnapshot.docs.map((doc) => {
      const { fullName, user_id } = doc.data();
      return { label: fullName, value: user_id };
    });

    agents.unshift({
      label: `${localStorage.getItem("respo_name")}`,
      value: localStorage.getItem("id"),
    });

    return agents;
  } catch (error) {
    return [];
  }
};

export const deleteAgentPermanently = async (idAgent) => {
  try {
    const agentData = (await getDoc(doc(userCollection, idAgent))).data();

    await setDoc(
      doc(userCollection, idAgent),
      { originalGroup_id: agentData.group_id },
      { merge: true }
    );
    return true;
  } catch (e) {
    console.error("Error updating the user group:", e);
    return false;
  }
};

export const getAgentBack = async (idAgent) => {
  try {
    const Respo_group_id = localStorage.getItem("group_id");

    await setDoc(
      doc(userCollection, idAgent),
      { group_id: Respo_group_id },
      { merge: true }
    );
    return true;
  } catch (e) {
    return false;
  }
};

export const getLoanAgents = async () => {
  try {
    const Respo_group_id = localStorage.getItem("group_id");

    const agentsQuery = query(
      userCollection,
      where("originalGroup_id", "==", Respo_group_id),
      where("role", "==", "Agent")
    );

    const querySnapshot = await getDocs(agentsQuery);
    const agents = querySnapshot.docs.map((doc) => doc.data());

    const groups = await getAllGroups();

    let data = [];
    agents.forEach(async (agent) => {
      if (agent.group_id !== agent.originalGroup_id) {
        let AgentGroup_id = agent.group_id;
        groups.forEach(async (gr) => {
          if (gr.group_id === AgentGroup_id) {
            let obj = {
              fullName: agent.fullName,
              user_id: agent.user_id,
              group_name: gr.group_name,
            };

            data.push(obj);
          }
        });
      }
    });

    return data;
  } catch (error) {}
};

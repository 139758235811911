import React, { useEffect, useState } from 'react';
// Packages :
import { Box, IconButton, Modal, Tooltip } from '@mui/material';
// Icons :
import { AiOutlineDelete } from 'react-icons/ai';
// Toast :
import { toast } from 'react-toastify';
// Functions & Firebase :
import { deleteDoc, doc } from 'firebase/firestore';
import { pointageCollection } from '../../config/firebase';

export const DeleteModal = ({ date, id, onDelete }) => {
    // ? ==========================|| Modal Style ||==========================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        borderRadius: 3
    };
    // ? =====================================================================

    // ? =============================|| Toast ||=============================
    const notifySuccess = () => toast.success('Le pointage a été supprimé avec succès', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const notifyError = () => toast.error('Il y a un problème, réessayez s\'il vous plaît', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? =====================================================================

    // ? ==========================|| Delete Pointage ||==========================
    const deletePointage = async (pointage_id) => {
        try {
            const deletedPointageRef = doc(pointageCollection, pointage_id);
            await deleteDoc(deletedPointageRef);
            onDelete()
        } catch (error) {
            notifyError();
        }
    };
    const handleDeletePointage = () => {
        try {
            deletePointage(id);
            notifySuccess();
            handleClose();
        } catch (error) {
            notifyError();
            handleClose();
        }
    };
    // ? ========================================================================

    return (
        <div>
            <Tooltip title="Delete" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineDelete size={20} color='red' />
                </IconButton>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Supprimer Pointage</p>
                    <div className='mt-6'>
                        <p className='text-lg mb-6'>Etes-vous sûr de vouloir supprimer ce pointage, {date} ?</p>
                        <div className='flex justify-end items-center'>
                            <button
                                onClick={handleClose}
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                            >
                                Annuler
                            </button>

                            <button
                                onClick={handleDeletePointage}
                                className='bg-primary text-white rounded-md py-2 px-4'
                            >
                                Oui, je suis sûr
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

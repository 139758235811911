import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal, Tooltip } from '@mui/material';
// Icons :
import { BiSelectMultiple } from "react-icons/bi";
import { verifySelected } from '../../config/verifyConfig';
// Toast :
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const VerifySelectedModal = ({ selectedPointage, onVerify }) => {
    // ? ============================|| Modal Style ||============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        borderRadius: 3
    };
    // ? ========================================================================= 

    // ? ===============================|| Toast ||===============================
    const notifySuccess = () => toast.success('Pointage des agents vérifié avec succès.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (error) => toast.info(`${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? ===============================================================================

    // ? ========================|| Verify Selected Pointage ||=========================
    const handleVerifySelectedPointage = () => {
        try {
            if (selectedPointage.length > 0) {
                verifySelected(selectedPointage);
                handleClose();
                notifySuccess();
                onVerify();
            } else {
                handleClose();
                notifyInfo('Vous n\'avez sélectionné aucun pointage');
            }
        } catch (err) {
            handleClose();
            notifyInfo('Il y a un problème, veuillez réessayer');
        }
    };
    // ? ===============================================================================


    return (
        <div>
            <Tooltip title="Vérifier le pointage sélectionné" onClick={handleOpen}>
                <button className='px-6 py-2 bg-primary flex justify-center items-center rounded-md lg:mr-4 text-white text-sm md:text-base'>
                    <BiSelectMultiple size={26} className='mr-2' />
                    Vérifier la sélection
                </button>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Vérifier le pointage sélectionné</p>
                    <div className='mt-6'>
                        <p className='text-lg mb-6'>Etes-vous sûr de vouloir Vérifier le pointage sélectionné ?</p>
                        <div className='flex justify-end items-center'>
                            <button
                                onClick={handleClose}
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                            >
                                Annuler
                            </button>
                            <button
                                onClick={handleVerifySelectedPointage}
                                className='bg-primary text-white rounded-md py-2 px-4'
                            >
                                Oui, je suis sûr
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
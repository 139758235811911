import React, { useEffect, useState } from "react";
// Material UI :
import { Box, Modal, IconButton, Tooltip } from "@mui/material";
// Toast :
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Icons :
import { AiOutlineSend } from "react-icons/ai";
// Functions :
import { getAgentBack } from "../../config/agentsConfig";
// Assets : 
import Loading from '../../assets/img/white-loading.gif';

export const GetBackAgent = ({ id, onGetBack }) => {
    const [isLoading, setIsLoading] = useState(false);

    // ? ============================|| Modal Style ||============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        borderRadius: 3
    };
    // ? =========================================================================

    // ? ===============================|| Toast ||===============================
    const notifySuccess = () => toast.success("agent is back to ur team successfully ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (error) => toast.info(`${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? ===============================================================================

    // ? ============================|| Send Agent To Another Responsable  ||============================
    const handleSendAgentToRespo = async () => {
        setIsLoading(true);
        try {
            await getAgentBack(id);
            onGetBack(); // Refresh Table 
            handleClose(); // Close the modal.
            notifySuccess();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            handleClose(); // Close the modal.
            notifyInfo("Il y a un problème, veuillez réessayer");
        }
    };
    // ? ================================================================================================

    return (
        <div>
            <Tooltip title="Revenir" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineSend size={20} color="green" className="rotate-180" />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className="text-3xl font-bold">Get Agnet back</p>
                    <div className="mt-6">
                        <p className="text-lg mb-6">
                            Etes-vous sûr de vouloir récupérer votre agent ?
                        </p>
                        <div className="flex justify-end items-center">
                            <button
                                onClick={handleClose}
                                className="border border-gray rounded-md py-2 px-4 mr-4"
                            >
                                Annuler
                            </button>
                            <button
                                className="bg-primary text-white rounded-md py-2 px-4"
                                onClick={handleSendAgentToRespo}
                            >
                                {
                                    isLoading ? <img src={Loading} alt='' className='w-6' /> : <p>Revenir</p>
                                }
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

import { pointageCollection, userCollection } from "./firebase";
import { getDocs, query, where } from "firebase/firestore";

const getDateForPointage = (selectedMonth) => {
  // Parse the selected month string, e.g., "2022-02"
  const [year, month] = selectedMonth.split("-");

  // Set the date to the first day of the selected month
  const startDate = new Date(parseInt(year), parseInt(month) - 1, 1);

  // Set the date to the last day of the selected month
  const endDate = new Date(parseInt(year), parseInt(month), 0);

  // Adjust to local time zone (GMT+1)
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  let date = {
    start: startDate,
    end: endDate,
  };

  return date;
};

export const getdata = async (month) => {
  try {
    const group_id = localStorage.getItem("group_id");

    const agents = await fetchAgents(group_id);
    const pointage = await fetchPointage(month, agents);
    const processedData = processData(agents, pointage, month);
    const sortedData = sortData(processedData);
    
    //   setData(sortedData); // Assuming setData is a function to set the data in your application state
    //   console.log("sortedData :", sortedData);
    //   getDays(month);

    return sortedData;
  } catch (error) {
    console.error("Error occurred:", error);
  }
};

const fetchAgents = async (group) => {
  try {
    const agentsQuery = query(
      userCollection,
      where("group_id", "==", group)
      // where("role", "==", "Agent")
    );

    const querySnapshot = await getDocs(agentsQuery);
    const agents = querySnapshot.docs.map((doc) => doc.data());

    return agents;
  } catch (error) {}
};

const fetchPointage = async (month, agents) => {
  const date = getDateForPointage(month);

  try {
    let pointage = [];

    // Use Promise.all to wait for all async operations to complete
    await Promise.all(
      agents.map(async (ag) => {
        const pointageQuery = query(
          pointageCollection,
          where("user_id", "==", ag.user_id),
          where("date", ">=", new Date(date.start)),
          where("date", "<=", new Date(date.end))
        );

        const querySnapshot = await getDocs(pointageQuery);
        const result = querySnapshot.docs.map((doc) => doc.data());

        result.map((res) => pointage.push(res));
      })
    );

    // //console.log(pointage)
    return pointage;
  } catch (error) {
    console.error("Error occurred:", error);
    throw error;
  }
};

const processData = (agent, pointage, month) => {
  let data = [];

  ////console.log("pointage :", pointage);

  agent.forEach((ag) => {
    let days = [];

    pointage.forEach((poi) => {
      const poiTimestamp =
        poi.date.seconds * 1000 + Math.round(poi.date.nanoseconds / 1e6);
      const poiDate = new Date(poiTimestamp);
      const today = poiDate.getDate();

      if (ag.user_id === poi.user_id) {
        let value;
        if (poi.normalWork.length > 0 && poi.verified === true) {
          value = 1;
        } else if (poi.normalWork.length > 0 && poi.verified === false) {
          value = 2;
        } else if (poi.totalHours === 0 && poi.recuperations === true) {
          value = 4;
        } else if (poi.totalHours === 0 && poi.conge === true) {
          value = 5;
        } else if (poi.totalHours === 0 && poi.absent === true) {
          value = 6;
        } else if (poi.totalHours === 0 && poi.malade === true) {
          value = 7;
        } else if (poi.totalHours === 0 && poi.feteAid === true) {
          value = 10;
        } else if (poi.totalHours === 0 && poi.holiday === true) {
          value = 11;
        }
        if (value !== undefined) {
          let affArray = [];

          if (poi.normalWork.length !== 0) {
            poi.normalWork.forEach((el) => {
              affArray.push(el.codeAffaire);
            });
          }
          if (poi.extraWork.length !== 0) {
            poi.extraWork.forEach((el) => {
              affArray.push(el.codeAffaire);
            });
          }

          // Remove duplicates from the array
          affArray = [...new Set(affArray)];

          // Concatenate unique codeAffaire values separated by '/'
          let codeAff = affArray.join(" / ");

          let m = {
            [today]: value,
            totalHours: poi.totalHours,
            codeAff: codeAff === "" ? "-" : codeAff,
          };
          days.push(m);
        }
      }
    });

    addMissingDays(days, month);

    days.sort(
      (a, b) => parseInt(Object.keys(a)[0]) - parseInt(Object.keys(b)[0])
    );

    let obj = {
      name: ag.fullName,
      days: days,
    };

    data.push(obj);
  });

  return data;
};

const addMissingDays = (days, month) => {
  const currentDate = new Date();
  const thisYear = currentDate.getFullYear();
  const thisMonth = currentDate.getMonth() + 1;

  const sentDate = new Date(month);
  const sentYear = sentDate.getFullYear();
  const sentMonth = sentDate.getMonth() + 1;

  const daysInMonth = new Date(sentYear, sentMonth, 0).getDate();

  if (thisYear === sentYear && thisMonth === sentMonth) {
    const today = currentDate.getDate();
    for (let i = 1; i <= daysInMonth; i++) {
      if (!days.some((day) => parseInt(Object.keys(day)[0]) === i)) {
        let m = {};
        let dt = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);

        if (isSunday(dt) && i <= today) {
          m = { [i]: 3, totalHours: 0, codeAff: "-" };
        } else {
          m = {
            [i]: i < today ? 8 : 9,
            totalHours: 0,
            codeAff: "-",
          };
        }
        days.push(m);
      }
    }
  } else if (thisYear >= sentYear && thisMonth >= sentMonth) {
    for (let i = 1; i <= daysInMonth; i++) {
      if (!days.some((day) => parseInt(Object.keys(day)[0]) === i)) {
        let m = {};
        let dt = new Date(sentDate.getFullYear(), sentDate.getMonth(), i);

        if (isSunday(dt)) {
          m = { [i]: 3, totalHours: 0, codeAff: "-" };
        } else {
          m = {
            [i]: 8,
            totalHours: 0,
            codeAff: "-",
          };
        }
        days.push(m);
      }
    }
  } else {
    for (let i = 1; i <= daysInMonth; i++) {
      if (!days.some((day) => parseInt(Object.keys(day)[0]) === i)) {
        let m = {
          [i]: 9,
        };
        days.push(m);
      }
    }
  }
};

const sortData = (data) => {
  return data.sort((a, b) => {
    const countA = a.days.filter((day) =>
      [1, 2].includes(Object.values(day)[0])
    ).length;
    const countB = b.days.filter((day) =>
      [1, 2].includes(Object.values(day)[0])
    ).length;
    return countB - countA;
  });
};

const isSunday = (dt) => {
  const date = new Date(dt);
  const dayOfWeek = date.getDay();
  return dayOfWeek === 0;
};

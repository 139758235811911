import React, { useEffect, useState } from "react";
// Material UI :
import { Box, Modal, Tooltip } from "@mui/material";
// Icons :
import { MdOutlineDomainVerification } from "react-icons/md";
import { AiOutlineDelete } from 'react-icons/ai';
// Toast :
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Functions :
import { deleteSelectedPointage } from "../../config/verifyConfig";

export const DeleteVerificationModal = ({ selectedPointage, onDelete }) => {
    // ? ============================|| Modal Style ||============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        borderRadius: 3
    };
    // ? =========================================================================

    // ? ===============================|| Toast ||===============================
    const notifySuccess = (messaage) => toast.success(`${messaage}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (error) => toast.info(`${error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? =========================================================================

    // ? ===========================|| Verify All Pointage ||=====================
    const handleDeletePointage = async () => {
        try {
            if (selectedPointage.length > 0) {
                await deleteSelectedPointage(selectedPointage);
                handleClose();
                notifySuccess('Pointage supprimé avec succès.');
                onDelete();
            } else {
                handleClose();
                notifyInfo('Il n\'y a pas de données');
            }
        } catch (err) {
            handleClose();
            notifyInfo('Il y a un problème, veuillez réessayer');
        }
    };
    // ? =========================================================================

    return (
        <div>
            <Tooltip title="Annuler la vérification" onClick={handleOpen}>
                <button className="px-6 py-2 bg-red flex justify-center items-center rounded-md text-white my-2 md:my-0 text-sm md:text-base">
                    <AiOutlineDelete size={26} className="mr-2" />
                    Annuler la vérification
                </button>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className="text-3xl font-bold">Annuler la vérification</p>
                    <div className="mt-6">
                        <p className="text-lg mb-6">
                            Êtes-vous sûr de vouloir supprimer la vérification ?
                        </p>
                        <div className="flex justify-end items-center">
                            <button
                                onClick={handleClose}
                                className="border border-gray rounded-md py-2 px-4 mr-4"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={handleDeletePointage}
                                className="bg-primary text-white rounded-md py-2 px-4"
                            >
                                Oui, je suis sûr
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};
